body {
  height: 100%;
  font-family:'Droid Serif', serif;
  color: #333;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
}
h1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 20px;
  line-height: 44px;
  font-weight: 700;
}
h2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 36px;
  font-weight: 400;
  text-transform: uppercase;
}
h3 {
  margin-top: 20px;
  margin-bottom: 20px;
  color: black;
  font-size: 24px;
  line-height: 30px;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
}
h4 {
  margin-top: 30px;
  margin-bottom: 10px;
  color: #555;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
}
h5 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 20px;
  font-weight: 700;
}
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 12px;
  line-height: 18px;
  font-weight: 700;
}
p {
  margin-bottom: 5px;
  color: #818181;
  font-size: 13px;
  line-height: 26px;
}
a {
  color: #323232;
  text-decoration: none;
}
.button {
  display: inline-block;
  margin-left: 10px;
  padding: 9px 17px;
  background-color: white;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  color: #71bbc8;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration: none;
}
.button:hover {
  background-color: transparent;
  box-shadow: white 0px -1px 0px 0px inset;
  color: white;
}
.button.button-color {
  margin-left: 0px;
  background-color: #71bbc8;
  color: white;
}
.button.button-color:hover {
  background-color: transparent;
  box-shadow: #71bbc8 0px -1px 0px 0px inset;
  color: #71bbc8;
}
.button.button-color.float-right {
  float: right;
}
.button.button-color.float-right:hover {
  box-shadow: #71bbc8 0px -1px 0px 0px inset;
  color: #71bbc8;
}
.button.button-color.maintenance {
  background-color: #d0e15f;
  color: white;
}
.button.button-color.maintenance:hover {
  background-color: transparent;
  box-shadow: #d0e15f 0px -1px 0px 0px inset;
  color: #d0e15f;
}
.button.button-color.lighting {
  background-color: #ecf2bd;
  color: black;
}
.button.button-color.lighting:hover {
  background-color: transparent;
  box-shadow: #e5ebb7 0px -1px 0px 0px inset;
  color: #dce69c;
}
.button.maintenance {
  color: #d0e15f;
}
.button.maintenance:hover {
  color: white;
}
.header {
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: white;
}
.header.bottom {
  text-align: center;
}
.navigation-link {
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
  font-family:'Droid Serif', serif;
  color: black;
}
.navigation-link:hover {
  color: #71bbc8;
}
.navigation-link.w--current {
  color: #71bbc8;
}
.brand {
  top: 4px;
  margin-top: -9px;
}
.brand.w--current {
  margin-top: -10px;
}
.banner {
  position: relative;
  height: 800px;
  background-color: #474747;
  background-image: none;
  background-position: 0% 0%;
  background-size: auto;
  background-repeat: repeat;
  background-attachment: scroll;
}
.banner.image2 {
  background-image: url('../images/DSC_0003.jpg');
  background-position: 50% 0px;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.banner.image1 {
  background-image: url('../images/ww4.png');
  background-position: 50% 100%;
  background-repeat: no-repeat;
}
.section {
  padding: 100px 10px;
}
.section.parallax {
  background-image: url('../images/060308-065-retouch_1.jpg');
  background-position: 50% 0px;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  text-transform: capitalize;
}
.section.parallax.inner-banner-7 {
  background-image: url('../images/DSC_0831-2.jpg');
  background-position: 50% 173%;
}
.section.parallax.inner-banner-8 {
  background-image: url('../images/DSC_0047.NEF.jpg');
  background-position: 50% 50%;
}
.section.parallax.inner-banner-9 {
  background-image: url('../images/IMG_2729.jpg');
  background-position: 50% 50%;
  background-size: auto;
}
.section.parallax.inner-banner-11 {
  background-image: url('../images/34985.jpg');
  background-position: 50% 100%;
  background-size: auto;
}
.section.parallax.inner-banner-1 {
  background-image: url('../images/34965.jpg');
  background-position: 50% 100%;
}
.section.parallax.image-banner-1 {
  background-image: url('../images/DSC_0041.jpg');
  background-position: 50% 100%;
  background-size: auto;
}
.section.about-intro {
  display: none;
}
.section.lastsection {
  padding-bottom: 160px;
}
.section.watersupplysection {
  padding-top: 20px;
  padding-bottom: 20px;
}
.top-text {
  text-align: center;
}
.top-text.developer {
  margin-bottom: 90px;
}
.space {
  margin-top: 60px;
}
.no-padding {
  padding-right: 0px;
  padding-left: 0px;
}
.left-big-image {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 650px;
  max-height: 650px;
  background-image: url('../images/Bupa007--1034.jpg');
  background-position: 100% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.left-service {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 650px;
  max-height: 650px;
  padding-top: 76px;
  padding-right: 10px;
  padding-left: 80px;
  background-color: #fafafa;
}
.small-left-services {
  margin-top: 30px;
}
.left-icon-services {
  width: 60px;
  height: 60px;
  margin-right: 30px;
  float: left;
  border-radius: 100%;
  background-color: #c0a756;
}
.left-icon-services.icon-1 {
  background-color: #c0a756;
  background-image: url('../images/star.png');
  background-position: 50% 50%;
  background-size: 17px;
  background-repeat: no-repeat;
}
.left-icon-services.icon-2 {
  background-color: #c0a756;
  background-image: url('../images/star.png');
  background-position: 50% 50%;
  background-size: 17px;
  background-repeat: no-repeat;
}
.left-icon-services.icon-3 {
  background-color: #71bbc8;
  background-image: url('../images/home_ffffff_100.png');
  background-position: 50% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
}
.left-service-wrapper {
  overflow-x: hidden;
  overflow-y: hidden;
}
.no-space {
  margin-top: 0px;
  -webkit-transition: color 300ms ease;
  transition: color 300ms ease;
  text-decoration: none;
}
.no-space:hover {
  text-decoration: none;
}
.no-space.manuallink {
  display: inline-block;
  margin-right: 30px;
  margin-bottom: 0px;
}
.paragraph-ttile {
  color: #333;
  font-size: 15px;
  line-height: 28px;
}
.paragraph-ttile.blog-text {
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: 1px;
  text-align: left;
}
.team-wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}
.team-overlay {
  position: absolute;
  left: 0px;
  top: 0px;
  display: none;
  width: 100%;
  height: 100%;
  background-color: rgba(113, 187, 200, 0.8);
  opacity: 0;
}
.social-icon-team {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}
.social-icon {
  width: 40px;
  height: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  opacity: 0.8;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.social-icon:hover {
  opacity: 1;
}
.hero {
  padding-top: 38px;
  padding-bottom: 38px;
  background-color: #71bbc8;
  color: white;
  font-size: 19px;
  line-height: 48px;
}
.hero.maintenance {
  background-color: #d0e15f;
}
.column-align-right {
  text-align: right;
}
.space-in-left {
  overflow-x: hidden;
  overflow-y: hidden;
  width: 50%;
  height: 300px;
  max-height: 300px;
  padding-top: 30px;
  padding-right: 39px;
  padding-left: 39px;
  float: left;
  background-color: #f4f4f4;
}
.space-in-left.photo {
  background-image: url('../images/42-image.jpg');
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.space-in-left.photo.photo-2 {
  background-image: url('../images/2_1.jpg');
}
.space-in-left.photo.photo-3 {
  background-image: url('../images/7.jpg');
}
.space-in-left.photo._2 {
  background-image: url('../images/45-image.jpg');
  background-position: 50% 100%;
}
.space-in-left.photo._3 {
  background-image: url('../images/22-image.jpg');
  background-position: 50% 66%;
}
.navigation-dot {
  position: relative;
  padding-top: 20px;
  opacity: 0.55;
  font-size: 10px;
}
.slider {
  height: auto;
  background-color: transparent;
}
.small-space {
  margin-top: 20px;
}
.small-space.more-space {
  margin-top: 40px;
}
.footer {
  position: relative;
  padding-top: 60px;
  padding-bottom: 111px;
  background-color: #323232;
}
.features-title {
  margin-top: 0px;
  color: white;
  font-size: 20px;
  text-transform: none;
}
.paragraph-lighter {
  color: white;
}
.image-in-footer {
  overflow-x: hidden;
  overflow-y: hidden;
  height: 140px;
  max-height: 300px;
}
.text-field {
  height: 50px;
  color: #919191;
}
.form-message {
  background-color: #c0a756;
  color: white;
}
.error-message {
  background-color: #ff6e6e;
}
.social-footer {
  width: 40px;
  height: 40px;
  margin-right: 10px;
  border-radius: 100%;
  background-color: #71bbc8;
  background-image: url('../images/facebook.svg');
  background-position: 50% 50%;
  background-size: 16px;
  background-repeat: no-repeat;
  -webkit-transition: all 300ms ease-in-out;
  transition: all 300ms ease-in-out;
}
.social-footer:hover {
  background-color: #1a1a1a;
}
.social-footer.twiter {
  background-image: url('../images/twitter.svg');
}
.social-footer.rss {
  background-image: url('../images/rss.svg');
}
.small-bottom-footer {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  padding-top: 25px;
  padding-bottom: 20px;
  background-color: #2e2e2e;
}
.center {
  text-align: center;
}
.inner-banner {
  position: relative;
  height: 500px;
  background-color: #707070;
  background-image: url('../images/Allium--hi--res.jpg');
  background-position: 50% 0px;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.inner-banner.inner-banner-2 {
  background-image: url('../images/Bupa007--1034.jpg');
  background-position: 50% 73%;
}
.inner-banner.inner-banner-3 {
  background-image: url('../images/DSCF1540.jpg');
  background-position: 50% 453%;
  background-size: auto;
}
.inner-banner.inner-banner-4 {
  background-image: url('../images/IMG_2729.jpg');
  background-position: 50% 0px;
}
.inner-banner.inner-banner-5 {
  background-image: url('../images/DSC_0010.jpg');
  background-position: 50% 50%;
}
.inner-banner.inner-banner-6 {
  background-image: url('../images/DSCF1481.jpg');
  background-position: 0px 0px;
  background-size: auto;
  text-transform: uppercase;
}
.inner-banner.inner-banner-7 {
  background-image: url('../images/DSC_0055.jpg');
  background-position: 50% -59px;
  background-size: auto;
}
.inner-banner.inner-banner-8 {
  background-image: url('../images/DSCF1530.jpg');
  background-position: 50% -266px;
  background-size: auto;
}
.inner-banner.inner-banner-9 {
  background-image: url('../images/13.jpg');
  background-position: 50% 47%;
}
.inner-banner.inner-banner-10 {
  background-image: url('../images/34976.jpg');
  background-position: 50% -239px;
}
.inner-banner.inner-banner-11 {
  background-image: url('../images/DSC_0024_edited-1.jpg');
  background-position: 50% 0px;
}
.inner-banner.inner-banner-12 {
  background-image: url('../images/060308-065-retouch.jpg');
  background-position: 50% 7px;
}
.inner-banner.inner-banner-13 {
  background-image: url('../images/Bupa009--1036.jpg');
}
.inner-banner.inner-banner-14 {
  background-image: url('../images/DSC_0023.jpg');
}
.arrow-pattern {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 100;
  height: 23px;
  background-image: url('../images/triangle.png');
  background-position: 50% 100%;
  background-repeat: no-repeat;
  opacity: 1;
}
.center-sub-text {
  position: relative;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  text-align: center;
}
.sub-title {
  display: inline-block;
  padding: 12px 22px;
  border-radius: 2px;
  background-color: #71bbc8;
  color: white;
}
.sub-title.maintenance {
  background-color: #d0e15f;
  color: white;
}
.sub-title.lighting {
  background-color: #f4f8d3;
  color: black;
}
.sub-title.irrigation {
  color: white;
}
.white {
  color: white;
}
.testimonials-wrapper {
  margin-top: 10px;
  padding-right: 40px;
  padding-left: 40px;
}
.testimonials-paragraph {
  color: white;
  font-size: 15px;
  line-height: 30px;
  font-style: italic;
  text-align: center;
}
.testimonials-name {
  margin-top: 0px;
  color: white;
  text-align: center;
}
.contact-icon {
  display: block;
  width: 80px;
  height: 80px;
  margin-right: auto;
  margin-bottom: 17px;
  margin-left: auto;
  border-bottom: 2px solid #71bbc8;
  background-image: url('../images/icon4.png');
  background-position: 50% 50%;
  background-size: 50px;
  background-repeat: no-repeat;
}
.contact-icon.icon-2 {
  background-image: url('../images/icon3.png');
  background-size: 40px;
}
.contact-icon.icon-3 {
  background-image: url('../images/icon2.png');
  background-size: 40px;
}
.contact-icon.icon-4 {
  background-image: url('../images/icon1.png');
  background-size: 40px;
}
.text-area {
  padding-bottom: 104px;
}
.center-container {
  position: relative;
  top: 45%;
  -webkit-transform: translate(0px, -50%);
  -ms-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}
.big-title-banner {
  font-family: Georgia, Times, 'Times New Roman', serif;
  color: white;
  font-size: 68px;
  line-height: 80px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
}
.color {
  color: #71bbc8;
}
.color.lighting {
  color: #e5ebb7;
}
.sub-text-title {
  font-family:'Open Sans', sans-serif;
  color: white;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 7px;
  text-transform: uppercase;
}
.navigation-menu.bottom {
  display: block;
  margin-right: auto;
  margin-left: auto;
  float: none;
  text-align: center;
}
.email {
  color: rgba(255, 255, 255, 0.85);
  text-decoration: none;
}
.email:hover {
  color: #71bbc8;
}
.teamrow {
  margin-top: 40px;
  margin-bottom: 40px;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
}
.blog-img {
  width: 100%;
}
.socialmedia {
  display: inline-block;
  overflow-x: visible;
  overflow-y: visible;
  margin-top: 20px;
  margin-right: 20px;
}
.socialmedia.facebook {
  margin-right: 16px;
}
.socialmedia.googleplus {
  margin-top: -1px;
}
.footer-img {
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 27px;
}
.bullet {
  font-weight: 700;
}
.homeslider {
  height: auto;
}
.slidernav {
  bottom: 20px;
}
.dropdownlist {
  margin-top: 13px;
}
.dropdownlist.w--open {
  background-color: rgba(0, 0, 0, 0.86);
}
.dropdownlink {
  padding-top: 13px;
  padding-bottom: 13px;
  border-top-color: #c2c2c2;
  color: white;
}
.dropdownlink:hover {
  color: #71bbc8;
  text-decoration: none;
}
.dropdownlink.w--current {
  color: #71bbc8;
}
.processsection {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #729194;
}
.bottomnav {
  position: relative;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 1000;
  margin-top: 60px;
  margin-bottom: -35px;
  padding-top: 13px;
  padding-bottom: 13px;
  background-color: #3b3b3b;
}
.footer-navigation-link {
  color: white;
}
.footer-navigation-link:hover {
  color: #71bbc8;
}
.footer-navigation-link.w--current {
  color: #71bbc8;
}
.tabs {
  display: none;
  margin-top: -7px;
  margin-bottom: 110px;
  padding-right: 50px;
  padding-left: 50px;
}
.tabmenu {
  padding-right: 7px;
  padding-left: 7px;
}
.tablink {
  width: 16.667%;
  padding: 14px 7px;
  background-color: transparent;
}
.tablink.w--current {
  background-color: transparent;
}
.tabimage {
  width: 100%;
}
.tabcontent {
  padding-right: 14px;
  padding-left: 14px;
}
.tabcontent-img {
  width: 100%;
}
.gallerycol {
  padding-right: 0px;
  padding-left: 0px;
}
.smalltext {
  font-size: 16px;
}
.accordian {
  width: 100%;
}
.accordianlist {
  position: relative;
}
.accordianlist.w--open {
  opacity: 0;
}
.accordiancontent {
  width: 100%;
  padding: 20px;
}
.accordiantoggle {
  display: block;
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  background-color: #71bbc8;
  -webkit-transition: height 500ms ease;
  transition: height 500ms ease;
  color: white;
  font-size: 17px;
}
.accordiantoggle.maintenance {
  background-color: #d0e15f;
}
.accordiantoggle.lighting {
  background-color: #f4f8d3;
  color: #333;
}
.faqwrapper {
  margin-top: 60px;
}
.home-img.irrigation {
  background-color: #71bbc8;
}
.home-img.maintenance {
  background-color: #d0e15f;
}
.home-img.lighting {
  background-color: #f0f5c5;
}
.gallerytitle {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 10px 12px;
  background-color: rgba(255, 255, 255, 0.82);
  color: black;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
}
.gallerytitle:hover {
  text-decoration: none;
}
.devlink {
  color: #71bbc8;
}
html.w-mod-js *[data-ix="fade-in-big-title-on-load"] {
  opacity: 0;
  -webkit-transform: translate(0px, -79px) scale(0.5);
  -ms-transform: translate(0px, -79px) scale(0.5);
  transform: translate(0px, -79px) scale(0.5);
}
html.w-mod-js *[data-ix="fade-in-sub-text-title-on-load"] {
  opacity: 0;
  -webkit-transform: translate(0px, 79px) scale(0.5);
  -ms-transform: translate(0px, 79px) scale(0.5);
  transform: translate(0px, 79px) scale(0.5);
}
html.w-mod-js *[data-ix="accordian-list"] {
  display: none;
  opacity: 0;
}
@media (max-width: 991px) {
  .navigation-link {
    max-width: 100%;
    margin-left: 0px;
    color: white;
  }
  .navigation-link.w--current {
    color: #71bbc8;
  }
  .navigation-link.dropdown {
    max-width: 100%;
  }
  .navigation-link.dropdown.w--open {
    margin-bottom: -14px;
  }
  .banner {
    height: 500px;
  }
  .banner.image2 {
    background-size: 1000px;
  }
  .banner.image1 {
    background-position: 50% 100%;
    background-size: 1000px;
  }
  .section {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  .left-service {
    height: 600px;
    max-height: 600px;
    padding-top: 54px;
    padding-left: 45px;
  }
  .hero {
    text-align: center;
  }
  .column-align-right {
    text-align: center;
  }
  .footer {
    padding-top: 29px;
    padding-bottom: 120px;
  }
  .inner-banner {
    height: 300px;
    background-size: 1000px;
  }
  .inner-banner.inner-banner-2 {
    background-position: 50% 195%;
    background-size: 990px;
  }
  .inner-banner.inner-banner-3 {
    background-position: 50% 0px;
    background-size: 1000px;
  }
  .inner-banner.inner-banner-5 {
    background-position: 50% 0px;
    background-size: 1000px;
  }
  .inner-banner.inner-banner-6 {
    background-size: 1000px;
  }
  .inner-banner.inner-banner-7 {
    background-position: 50% 0px;
    background-size: 990px;
  }
  .inner-banner.inner-banner-8 {
    background-size: 990px;
  }
  .inner-banner.inner-banner-10 {
    background-position: 50% -108px;
  }
  .inner-banner.inner-banner-13 {
    background-position: 100% 0%;
  }
  .center-container {
    top: 40%;
  }
  .big-title-banner {
    font-size: 58px;
  }
  .navigation-menu {
    margin-right: 0px;
    margin-left: 0px;
    padding-right: 0px;
    padding-left: 0px;
    background-color: rgba(0, 0, 0, 0.86);
  }
  .navigation-menu.bottom {
    background-color: transparent;
  }
  .hamburger.w--open {
    border-radius: 100%;
    background-color: #71bbc8;
  }
  .dropdownlist.w--open {
    background-color: transparent;
  }
  .dropdownlink {
    padding: 20px 40px;
    background-color: transparent;
  }
  .dropdown {
    max-width: 100%;
    background-color: transparent;
  }
}
@media (max-width: 767px) {
  .banner {
    padding-right: 20px;
    padding-left: 20px;
  }
  .banner.image1 {
    background-size: 800px;
  }
  .service-wrapper {
    margin-bottom: 40px;
  }
  .left-service {
    height: 650px;
    max-height: 650px;
    padding-top: 30px;
    padding-right: 22px;
    padding-left: 22px;
  }
  .paragraph-ttile {
    font-size: 13px;
  }
  .team-overlay {
    display: block;
  }
  .space-in-left {
    width: 100%;
    height: auto;
    padding-bottom: 30px;
  }
  .space-in-left.photo {
    width: 100%;
    height: 300px;
  }
  .inner-banner {
    background-size: 850px;
  }
  .inner-banner.inner-banner-2 {
    background-position: 50% 1305%;
    background-size: 850px;
  }
  .inner-banner.inner-banner-3 {
    background-size: 760px;
  }
  .inner-banner.inner-banner-5 {
    background-size: 800px;
  }
  .inner-banner.inner-banner-6 {
    background-position: -46px 0px;
    background-size: 960px;
  }
  .inner-banner.inner-banner-7 {
    background-size: 840px;
  }
  .inner-banner.inner-banner-8 {
    background-size: 880px;
  }
  .inner-banner.inner-banner-10 {
    background-position: 50% -38px;
  }
  .inner-banner.inner-banner-13 {
    background-position: 100% 0%;
  }
  .big-title-banner {
    font-size: 48px;
    line-height: 55px;
  }
  .sub-text-title {
    font-size: 10px;
    line-height: 34px;
  }
  .ipad-space {
    margin-bottom: 30px;
  }
  .footer-navigation-link {
    padding-right: 13px;
    padding-left: 13px;
  }
  .hide {
    display: none;
  }
}
@media (max-width: 479px) {
  .banner {
    padding-right: 20px;
    padding-left: 20px;
  }
  .banner.image2 {
    background-position: 50% 0px;
  }
  .left-service {
    height: auto;
    max-height: 766px;
    padding-bottom: 30px;
  }
  .hero {
    line-height: 30px;
  }
  .column-align-right {
    margin-top: 15px;
  }
  .inner-banner {
    background-size: 690px;
  }
  .inner-banner.inner-banner-2 {
    background-position: 50% -385px;
  }
  .inner-banner.inner-banner-3 {
    background-size: 590px;
  }
  .inner-banner.inner-banner-5 {
    background-size: 720px;
  }
  .inner-banner.inner-banner-6 {
    background-position: -128px 0px;
  }
  .inner-banner.inner-banner-10 {
    background-position: 50% 42px;
  }
  .inner-banner.inner-banner-12 {
    background-size: 830px;
  }
  .testimonials-wrapper {
    padding-right: 20px;
    padding-left: 20px;
  }
  .big-title-banner {
    font-size: 38px;
    line-height: 42px;
  }
  .color {
    display: block;
  }
  .color.irrigation {
    margin-bottom: 10px;
  }
  .sub-text-title {
    line-height: 16px;
  }
  .footer-navigation-link {
    display: block;
    padding-right: 10px;
    padding-left: 10px;
    font-size: 13px;
    text-align: center;
  }
}

