/* Global Values */
img {
  max-width: 100%;
}
div.p {
  color: #818181;
  font-size: 13px;
  line-height: 26px;
  margin-bottom: 5px;
}
footer div.p {
  color: white;
}
div.paragraph-ttile {
  color: #333;
  font-size: 15px;
  line-height: 28px;
}
img.logo,
.logo {
  width: 220px;
}
.banneroverlay .center-container,
.parallax .w-container {
  background-color: rgba(0, 0, 0, 0.33);
  padding: 20px;
}
/*.w-slider-mask .banner:first-child{
    background-attachment: fixed;
}*/
.banne, .parallax {
  background-attachment: fixed;
  background-position: 50% 50%;
  background-size: cover;
}
.big-title-banner,
.sub-text-title,
.big-title-banner-blue,
.sub-text-title-blue {
  display: inline-block;
  text-transform: none;
  margin: 0;
  width: 100%;
}
.big-title-banner-blue {
  font-family: Georgia, Times, "Times New Roman", serif;
  font-size: 68px;
  line-height: 80px;
  font-style: italic;
  font-weight: 400;
  text-align: center;
  color: #71BBC8;
}
.sub-text-title-blue {
  font-family: "Open Sans", sans-serif;
  color: #71BBC8;
  font-size: 16px;
  line-height: 50px;
  text-align: center;
  letter-spacing: 7px;
  text-transform: uppercase;
}
.iconMonster {
  width: 50px;
}
.service-wrapper img {
  width: 260px;
}
.aboutSlider h3 {
  background-color: #71bbc8;
  border-radius: 2px;
  color: white;
  display: inline-block;
  padding: 12px 22px;
}
.aboutSlider .banner {
  height: 500px;
  text-align: center;
}
.banner {
background-size:cover!important;
}
.teamItem {
  margin-top: 50px;
}
.lightingSlider h3 {
  background-color: #f4f8d3;
  color: black;
}
.maintenanceSlider h3 {
  background-color: #d0e15f;
  color: white;
}
.faq-item {
  margin-top: 20px;
}
.faq-item .mbss-listItem-text-wrapper:nth-child(2) .mbss-content {
  padding: 20px 15px;
  background: #dddddd;
  min-width: 100%;
  min-height: 0 !important;
}
.faq-item .mbss-listItem-text-wrapper:nth-child(1) .mbss-content {
  color: white;
  display: block;
  font-size: 17px;
  padding: 15px;
  width: 100%;
  position: relative;
  cursor: pointer;
}
.irrigationFaqItem div.mbss-listItem-text-wrapper:first-child {
  background-color: #71bbc8;
}
.maintenanceFaqItem div.mbss-listItem-text-wrapper:first-child {
  background-color: #d0e15f;
}
.lightingFaqItem div.mbss-listItem-text-wrapper:first-child {
  background-color: #f4f8d3;
}
.lightingFaqItem div.mbss-listItem-text-wrapper:first-child .mbss-content {
  color: #333;
}
.mbss-text-fader-wrapper.w-slider {
  min-height: 50px;
  position: relative;
  background-color: transparent;
  width: auto;
  color: white;
  font-size: 15px;
  font-style: italic;
  line-height: 30px;
  text-align: center;
}
.mbss-text-fader-wrapper.w-slider h4 {
  font-style: normal;
}
.section.parallax {
  background-position: 50% 100% !important;
}
.processwrapper {
  display: block;
  margin-top: 18px;
  margin-bottom: 18px;
  padding: 30px 30px 25px 25px;
  background-color: #759194;
}
.process-numbering {
  position: relative;
  z-index: 100;
  width: 60px;
  height: 60px;
  border: 6px solid white;
  border-radius: 100%;
  background-color: #759194;
  font-size: 30px;
  line-height: 41px;
  font-style: italic;
  text-align: center;
}
.process-numbering.process-numbering-6 {
  line-height: 47px;
}
.process-numbering.process-numbering-8 {
  line-height: 48px;
}
.processtext {
  position: relative;
  z-index: 0;
  display: inline-block;
  width: 100%;
  min-height: 48px;
  margin-top: -53px;
  margin-left: 15px;
  padding: 6px 10px 6px 50px;
  float: left;
  border-radius: 15px;
  background-color: white;
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  letter-spacing: -0.2px;
  color: black;
}
.processtext.processtext-step4 {
  font-style: italic;
}
.process-step {
  margin-bottom: 10px;
}
.section.parallax.inner-banner-11 {
  background-image: url("../webflow/images/34985.jpg");
}
.waterSuppliesText h3 {
  text-align: center;
}
.section.parallax.inner-banner-9 {
  background-image: url("site-images/custom/IMG_2729.jpg");
  background-size: cover;
}
.left-big-image2 {
  height: 650px;
  overflow: hidden;
}
.left-big-image2 .mbss-image {
  display: block;
}
.left-big-image2 img {
  max-width: 100%;
}
.left-service-wrapper h4 {
  margin-top: 0;
}
.manualItem h1,
.manualItem h2 {
  display: inline-block;
  margin-bottom: 0;
  margin-right: 30px;
  font-size: 20px;
  font-weight: 700;
  line-height: 44px;
  text-transform: none;
}
.contact-btn {
  float: left;
}
.homeTestimonials .space-in-left {
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: cover;
}
.homeTestimonials {
  color: #818181;
  font-size: 13px;
  line-height: 26px;
}
.homeTestimonials a {
  display: table;
  clear: both;
  font-size: 14px;
  line-height: 20px;
  padding: 9px 17px;
  text-align: center;
  text-decoration: none;
  -moz-transition: all 300ms ease-in-out 0s;
  -o-transition: all 300ms ease-in-out 0s;
  -webkit-transition: all 300ms ease-in-out 0s;
  transition: all 300ms ease-in-out 0s;
  margin-top: 20px;
  background-color: #71bbc8;
  color: white;
  margin-left: 0;
}
.homeTestimonials a:hover {
  background-color: transparent;
  box-shadow: #71bbc8 0px -1px 0px 0px inset;
  color: #71bbc8;
}
.testimonial-title {
  color: #555;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 10px;
}
.testimonial-text {
  font-size: 13px;
  color: #818181;
}
.postSummaryContent {
  max-height: 76px;
  overflow: hidden;
}
.inner-banner.inner-banner-2 {
  background-position: 50% 73%;
}
.signup-btn {
  background-image: url('data:image/gif;base64,R0lGODlhIgAiAOYAAAUuVqnJO6nKPAs1VmOIR6vLNkBoTWWKR1J4S0tyTFd9SiFKUzBYUKbGOHqcQoOmQYOlQIiqQG6SRgozVqnIN0JoTS1WUUFpTVl+STpiT0xzTFh9S32gQ2+TRWiMR4+xPjFaUD5mTydQUpKzPWKHRzVcUA84VihRUhdAVCdQUyxVUS1VURdBVJ+/Op6/OnudQq7NNi5WUVB2TAgxV0VsTRpEVFF4SqLDOZS1PAkzVlF3SkRrTQkyVxM9VafGOEduTaLBOWqORhxFU6nJNomrPxQ+VZO1PBdCVVR6Sn+hQg43VrDPNhhBVSBIU6nJPAYuV6jJO7TTNAYvVwUuV7XUNLXTNLTTMwAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAAAAAAALAAAAAAiACIAQAf/gFBVVlZUhYZUhoNVVFVRjYaEjYVVlYNOAoOSj1aak5qMnZqEhJVWUVBOkppSrVNTGxQMr1NPIEMKU1K0pZZOAYqRp6KdxKGHjJOFhU5QpKSJhlGl1M/HnoWp1ImWkYrPj4yj1FVOToikKTceu666T7TtQUAi6IkBzs9U7RUIrgDvXknR8EPXFGiJoDjzxK3huG/FtkVrZgoSlUeNLEk6hG5ZLyrmQE0TFQ0UN3GkPCVTmBHZMkZRpkUxZYrStWksiVnRZQLChx5PWu2qJaXIiAdKXoWKpBAhFYGtAFrw4eBFgxVPssLbBY2RtkWEJhBQkXWo2Xa6ThzIMYqRuVOa/6aFWoRokLCX1aw0o/TR7rJoHC3WLNV02kVFMzt6BBtxZKW9jvgqehgq8SFJ35rNhFYsEtjI4kZCWlROlcWunRyT/CtqJKG3KVcdE0ZXtLdiAgL0YgRDBi2D7eAB2BXUxhK5lBSCLjSFBQ4OA7ZCDTplQBIjTKagvKSqEyKhPDq0aBJQ1wIXEma4SpbICkvXVoK6enKhAA1dOwoYMPjqycXNVATgBCiE6NJKDDpI8cQRBJCAwi4JlDDcK50ZUthorSARQla0wCPdFBlgAE97ojR1DRFCKDihfALV4mINEVRiWWGXxUbTMx5xwp6FglzUGTEXsXfKZoUAqMxb43hk0x9u6CSz0SBf2aOSKJ9FVKQ3IOWjZGQ++sWNd0ulVE4gADs=') /*site-images/custom/mail-icon.gif*/;
  height: 35px;
  width: 35px;
  display: inline-block;
}
#signup-tbx {
  height: 35px;
  line-height: 35px;
}
ul li {
  list-style: outside none disc;
  margin-left: 15px;
}
.footer ul li {
  margin-left: 0;
}
.left-service {
  height: auto;
  max-height: none;
}
.foooterLink {
  color: #818181;
}
.inner-banner
{
    background-size:cover;
}
.overlay
{
    width:auto;
}
.slider.homeTestimonials ul.redactor-toolbar {
    top:-28px!important;
}
.slider.homeTestimonials .mbss-banner-text .mbss-btn-icon-cancel, .slider.homeTestimonials .mbss-banner-text .mbss-btn-icon-ok {
    display: inline-block !important;
}
.section.parallax {
text-transform:initial;
}
.left-service-wrapper .mbss-textArea-btns {
top:90%;
}
.left-service-wrapper {
overflow:auto;
margin-left:90px;
}

    .left-service-wrapper .mbss-text ul.redactor-toolbar {
        top:-30px!important;
    }
    .left-service-wrapper li {
    list-style:disc;
    }
.teamItem .mbss-listItem-text p {
    margin-bottom: 0;
    color: initial;
    font-size: unset;
    line-height: unset;
}
.w-slide .testimonials-wrapper p{
padding:10px 20px;
border-left:5px solid #e2e2e2;
font-size:18px;
line-height:22px;
margin: 0 0 10px;
color:#fff;
}
.slider.homeTestimonials ul.mbss-textArea-btns {
top:initial;
}
    /* Size specific */
    /* all devices (tablets, 768px and up) */
    @media (min-width: 767px) {
        .left-service {
            /*height:650px;
        max-height:650px;*/
        }     
    }
@media (max-width: 767px) {
    .left-big-image2 {
        height: 100%;
    }
    .left-icon-services {
        margin-right:5px;
    }
    .left-service-wrapper {
    margin-left:0;
    }
}

    @media (max-width: 479px) {
        .big-title-banner-blue {
            font-size: 38px;
            line-height: 42px;
        }

        .w-icon-slider-left,
        .w-icon-slider-right {
            display: none;
        }

        .sub-text-title {
            letter-spacing: 4px;
            font-size: 12px;
        }
        .left-icon-services {
           float:none;
        }
        .faqwrapper .w-icon-dropdown-toggle {
        margin:auto 10px auto auto;
        }
        .faqwrapper .faq-item .mbss-listItem-text-wrapper:nth-child(1) .mbss-content {
        padding-right:20px;
        }
        .form-horizontal .contact-btn.button.button-color {
        margin:10px auto 15px auto;
        }
    }

    @media screen and (max-width: 1268px) {
        .left-service {
            padding-top: 37px;
            padding-left: 60px;
        }
    }