@font-face {
    font-family: 'pf-fontello-icons';
    src: url('../fonts/pf-fontello-icons.eot?27881165');
    src: url('../fonts/pf-fontello-icons.eot?27881165#iefix') format('embedded-opentype'), url('../fonts/pf-fontello-icons.woff2?27881165') format('woff2'), url('../fonts/pf-fontello-icons.woff?27881165') format('woff'), url('../fonts/pf-fontello-icons.ttf?27881165') format('truetype'), url('../fonts/pf-fontello-icons.svg?27881165#pf-fontello-icons') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="pf-icon-"]:before, [class*=" pf-icon-"]:before {
    font-family: "pf-fontello-icons";
    font-style: normal;
    font-weight: normal;
    speak: none;
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.pf-icon-add-to-fav:before {
    content: '\e804';
}
.pf-icon-is-fav:before {
    content: '\e803';
}